/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";

import { COLORS } from "../config";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { isEmpty, isNil } from "ramda";

const TimeLine = (props) => {
  const { ieso = false, year = 0, yearSchools = [], frontPage = false } = props;

  let colorsUsed = [];

  function shuffler(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  const buildItemC = (item) => {
    let cColor = changeColor();

    return (
      <Card key={item.id} className="text-center card w-20">
        <Card.Body
          style={{ backgroundColor: cColor.backgroundColor }}
          className="text-center"
        >
          <Card.Title className="text-center">
            <Link
              onClick={() => !!props.clearOpg && props.clearOpg()}
              to={`/${ieso ? "ieso" : "opg"}/${item.year.year}`}
              style={{ color: `${cColor.color} !important` }}
            >
              {!ieso && (
                <h3>
                  {item.year.year - 1} <br /> - <br />
                  {item.year.year}
                </h3>
              )}

              {ieso && <h3>{item.year.year}</h3>}
            </Link>
          </Card.Title>
        </Card.Body>
      </Card>
    );
  };

  const changeColor = () => {
    let choosenColor = {};

    const shuffled = shuffler(COLORS);

    for (let index = 0; index < shuffled.length; index++) {
      const item = shuffled[index];

      if (!colorsUsed.includes(item.backgroundColor)) {
        colorsUsed.push(item.backgroundColor);
        choosenColor = item;
        break;
      }
    }

    return choosenColor;
  };

  if (!isNil(yearSchools) && !isEmpty(yearSchools)) {
    return (
      <div className="p-0">
        <div className="row">
          {(yearSchools.length > 1 || frontPage) && (
            <h2 className="txt-darkorange bold mb-1 text-uppercase">Edições</h2>
          )}
          <div className="col-12 m-0 p-0">
            <div className="w-100">
              <Carousel
                indicators={false}
                className="w-100"
                controls={false}
                fade={true}
                interval={5000}
              >
                <Carousel.Item className="w-100" key={Math.random()}>
                  <div className="card-group w-100" key={Math.random()}>
                    {yearSchools.map(
                      (item) =>
                      parseInt(year) !== parseInt(item.year.year) && buildItemC(item)
                    )}
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!yearSchools} />
      </div>
    );
  }
};

export default TimeLine;
