/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const { dynamicFooter = [] } = props;
  return (
    <>
      <div className="row bg-lightest mt-3">
        <div className="col">
          <h3 className="txt-darkgreen text-left text-uppercase bold mt-3 mb-3">
            Apoios e Parceiros
          </h3>
        </div>
      </div>
      <div className="row bg-lightest p-3 text-center mb-5">
        <div className="col-2">
          <a href="https://www.dge.mec.pt/" target="_blank" rel="noreferrer">
            <img
              src="/images/img_logo_me.png"
              alt="Governo de Portugal"
              title="Governo de Portugal"
              style={{ maxHeight: "75px" }}
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-2">
          <a
            href="https://www.cienciaviva.pt/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/logotipo.png"
              alt="Ciência Viva"
              title="Ciência Viva "
              className="img-fluid"
              style={{ maxHeight: "75px" }}
            />
          </a>
        </div>
        <div className="col-2">
          <a
            href="https://lousal.cienciaviva.pt/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/logo_Lousal.png"
              alt="Ciência Viva - Lousal"
              title="Ciência Viva - Lousal"
              className="img-fluid"
              style={{ maxHeight: "75px" }}
            />
          </a>
        </div>
        <div className="col-2">
          <a
            href="http://www.ccvestremoz.uevora.pt/home/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/ccve.png"
              style={{ maxHeight: "75px" }}
              alt="Ciência Viva - Estremoz"
              title="Ciência Viva - Estremoz"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-2">
          <a
            href="https://www.azoresgeopark.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/geoparque_acores.png"
              style={{ maxHeight: "75px" }}
              alt="Geoparque Açores"
              title="Geoparque Açores"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="col-2">
          <a
            href="https://gulbenkian.pt/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/fcg.png"
              style={{ maxHeight: "75px" }}
              alt="Fundação Calouste Gulbenkian"
              title="Fundação Calouste Gulbenkian"
              className="img-fluid"
            />
          </a>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-12">
          <small>
            <div className="row">
              <div className=" d-flex flex-row ">
                {dynamicFooter.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Link
                        to={`/pages/${item.slug}`}
                        key={item.id}
                        className={`link dropdown-item d-flex ${
                          index === 0
                            ? "justify-content-end"
                            : "justify-content-start"
                        }`}
                      >
                        {item.description}
                      </Link>
                      {index === 0 || index + 1 < dynamicFooter.length ? (
                        <span className=" mt-1">|</span>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </small>
        </div>
      </div>
    </>
  );
};
export default Footer;
