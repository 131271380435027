/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { filter, compose, not, propEq, isEmpty, sortBy, prop } from "ramda";

const LinkType = (props) => {
  const { linkTypes = [], national = true } = props;

  return (
    <>
      <div className="row">
        <div className="col">
          <h2 className="txt-brown text-uppercase mt-3 mb-3">
            Ligações {national ? "nacionais" : "internacionais"}{" "}
          </h2>
        </div>
      </div>

      <div className="row">
        {linkTypes.length === 0 && (
          <span> Não existe Informação para apresentar...</span>
        )}

        <Accordion defaultActiveKey="0">
          {sortBy(prop("description"), linkTypes).map((linkType, index) => {
            const filtered = filter(
              compose(not, propEq("national", !national)),
              linkType.link_opgs
            );
            return (
              !isEmpty(filtered) && (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{linkType.description}</Accordion.Header>
                  <Accordion.Body>
                    {sortBy(prop("title"), filtered).map((item) => {
                      return (
                        <div key={item.id} className="pt-1">
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            
                            aria-label={item.title}
                          >
                            {item.title} <br />
                          </a>
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              )
            );
          })}
        </Accordion>
      </div>
    </>
  );
};

export default LinkType;
