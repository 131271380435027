/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { isNil, range, isEmpty } from "ramda";

const TimeLineTests = (props) => {
  const {
    yearBegin = null,
    yearEnd = null,
    title = "",
    colors = [],
    presentYers = [],
    updateFunction,
    data = [],
    type = null,
  } = props;

  const buildYearsButton = (begin, end, colors, presentYers) => {
    return (
      <div className="d-flex justify-content-center p-2">
        {range(begin, end).map((index, iterator) => {
          const color = colors[iterator];
          if (presentYers.includes(index)) {
            return (
              <span
                key={`${index}_${iterator}`}
                className="mx-1 btn btn-small w-100 p-2 shadow"
                style={{
                  backgroundColor: color.backgroundColor,
                  color: color.color,
                  border:
                    !isEmpty(data) &&
                    ((type === "sf" && data.includes(index)) ||
                      (type === "rp" && data.includes(index)) ||
                      (type === "fp" && data.includes(index)))
                      ? "4px solid #6c4b1a"
                      : "0px",
                }}
                onClick={(e) => updateFunction(index)}
              >
                <b>
                  {index - 1} - {index}
                </b>
              </span>
            );
          } else {
            return "";
          }
        })}
      </div>
    );
  };

  if (!isNil(yearBegin) && !isNil(yearEnd) && !isEmpty(colors)) {
    return (
      <div className="p-0">
        <div className="row">
          <h2 className="txt-darkorange bold mb-1 text-uppercase">{title}</h2>
          <div className="col-12">
            <div key={Math.random()}>
              {buildYearsButton(yearBegin, yearEnd + 1, colors, presentYers)}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default TimeLineTests;
