/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";

const getDynamicPages = () => {
  return axios.get(`${API_PATH}v1/dynamic_pages?type=2`);
};

const getDynamicPage = (id) => {
  return axios.get(`${API_PATH}v1/dynamic_pages/${id}?type=2`);
};


export default {
  getDynamicPages,
  getDynamicPage
};
