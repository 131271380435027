import React from "react";
import YouTube from "react-youtube";
import DOMPurify from "dompurify";
import Carousel from "react-bootstrap/Carousel";
import { isEmpty, isNil } from "ramda";

const IesoOpgContent = (props) => {
  const youTubeGetID = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
  };

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      width: "100%",
    },
  };

  const {
    title = "",
    content = "",
    children,
    title_s = "",
    final_phase_rank = null,
    p_list = null,
    pt_team = null,
    school_list = null,
    content_s = "",
    opg_image = null,
    images = [],
    videos = [],
    other_files = [],
    test_files = [],
    tests_answers = [],
    mgq_linkgs = [],
  } = props;

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-sm-10">
            <div className="row">
              <div className="col-12">
                <h2 className="txt-darkorange bold mb-3">{title}</h2>
                {!isNil(content) && (
                  <div
                    className="pt-2"
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        content.replaceAll("<img", '<img class="img-fluid" '),
                        {
                          ALLOWED_ATTR: [
                            "style",
                            "class",
                            "type",
                            "href",
                            "rel",
                            "src",
                            "target",
                          ],
                          ALLOWED_TAGS: [
                            "p",
                            "img",
                            "br",
                            "u",
                            "b",
                            "strong",
                            "table",
                            "tbody",
                            "tr",
                            "td",
                            "a",
                          ],
                        }
                      ),
                    }}
                  />
                )}
                {(!isNil(final_phase_rank) || !isNil(school_list)) && (
                  <div className="row">
                    <div className="col-12 text-center d-flex justify-content-start pb-4">
                    {!!final_phase_rank && <a
                        href={final_phase_rank}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary  btn-success btn-fixed-width shadow"
                      >
                        Classificação Final
                      </a>}
                      &nbsp;&nbsp;
                      {!!school_list && <a
                        href={school_list}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-secondary  shadow btn-fixed-width"
                      >
                        Lista Escolas
                      </a>}
                    </div>
                  </div>
                )}
                {(!isNil(p_list) || !isNil(pt_team)) && (
                  <div className="row">
                    <div className="col-12 text-center d-flex justify-content-start pb-4">
                      {!!p_list && <a
                        href={p_list}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary  btn-success btn-fixed-width shadow"
                      >
                        Países Participantes
                      </a>}
                      &nbsp;&nbsp;
                      {!!pt_team && <a
                        href={pt_team}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-secondary  shadow btn-fixed-width"
                      >
                        Equipa Portuguesa
                      </a>}
                    </div>
                  </div>
                )}

                {!isEmpty(other_files) && (
                  <div className="p-t-2 p-b-3 col-sm-12 col-md-6">
                    <h4 className="txt-darkorange  bold mb-1">
                      Outros Documentos
                    </h4>

                    {other_files.map((itemP, i) => {
                      return (
                        <p>
                          <a
                            href={itemP.document}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {itemP.description}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                )}

                {!isNil(test_files) && !isEmpty(test_files) && (
                  <div className="p-t-2 p-b-3 col-sm-12 col-md-6">
                    <h4 className="txt-darkorange  bold mb-1">Testes</h4>

                    {test_files.map((itemP, i) => {
                      return (
                        <p>
                          <a
                            href={itemP.document}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {itemP.opg_documents_type.description
                              ? itemP.opg_documents_type.description
                              : itemP.ieso_documents_type.description}
                            {!!itemP.phase_description && ` (${itemP.phase_description})` }
                          </a>
                        </p>
                      );
                    })}
                  </div>
                )}

                {!isNil(tests_answers) && !isEmpty(tests_answers) && (
                  <div className="p-t-2 p-b-3 col-sm-12 col-md-6">
                    <h4 className="txt-darkorange  bold mb-1">
                      Testes - Resolução
                    </h4>

                    {tests_answers.map((itemP, i) => {
                      return (
                        <p>
                          <a
                            href={itemP.document}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {itemP.ieso_tests_answers_type.description}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                )}

                {!isNil(mgq_linkgs) && !isEmpty(mgq_linkgs) && (
                  <div className="p-t-2 p-b-3 col-sm-12 col-md-6">
                    <h4 className="txt-darkorange  bold mb-1">
                      Ligações / Divulgação:
                    </h4>

                    {mgq_linkgs.map((itemP, i) => {
                      return (
                        <p>
                          <a
                            href={itemP.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {itemP.title}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {(!isEmpty(images) || !isEmpty(videos)) && (
              <div className="row">
                <div className="col-12 col-xs-12">
                  <h2 className="txt-green bold mb-1">GALERIA</h2>
                </div>

                {!isEmpty(images) && (
                  <div className="col-md-6 col-sm-12">
                    <div className="p-t-2 p-b-3">
                      <h4 className="txt-darkorange bold mb-1">Imagens</h4>
                      <Carousel
                        controls={false}
                        indicators={true}
                        fade={true}
                        interval={5000}
                        className="w-100"
                      >
                        {images.map((itemP, i) => {
                          return (
                            <Carousel.Item
                              key={itemP.id}
                              style={{ maxHeight: "360px" }}
                            >
                              <img
                                className="slide-custom"
                                alt="imagem"
                                src={itemP.document}
                                style={{ maxHeight: "360px" }}
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                )}

                {!isEmpty(videos) && (
                  <div className="p-t-2 p-b-3 col-sm-12 col-md-6">
                    <h4 className="txt-darkorange  bold mb-1">Vídeos</h4>
                    <Carousel
                      controls={false}
                      indicators={true}
                      fade={true}
                      interval={null}
                      className="w-100"
                    >
                      {videos.map((itemP, i) => {
                        return (
                          <Carousel.Item key={itemP.id}>
                            <YouTube
                              videoId={youTubeGetID(itemP.url)}
                              containerClassName="embed embed-youtube"
                              opts={opts}
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                )}
              </div>
            )}
          </div>
          <div class="col-sm-2 text-center">
            <img src={opg_image} alt={title} className="img-fluid w-100" />
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-12 col-xs-12">
            {title_s !== "" && (
              <h2 className="txt-darkorange bold mb-1 p-t-2">{title_s}</h2>
            )}
            {!isNil(content_s) && (
              <div
                className="pt-2"
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    content_s.replaceAll("<img", '<img class="img-fluid" '),
                    {
                      ALLOWED_ATTR: [
                        "style",
                        "class",
                        "type",
                        "href",
                        "rel",
                        "src",
                        "target",
                      ],
                      ALLOWED_TAGS: [
                        "p",
                        "img",
                        "br",
                        "u",
                        "i",
                        "em",
                        "b",
                        "table",
                        "strong",
                        "tbody",
                        "tr",
                        "td",
                        "a",
                      ],
                    }
                  ),
                }}
              />
            )}
            {isNil(content_s) && <div className="pt-3"></div>}
            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IesoOpgContent;
