import React, { useState, useEffect } from "react";

import OpgService from "../services/opgs.service";
import EventBus from "../common/EventBus";
import Loader from "./Loader";
import DynamicContent from "./DynamicContent";
import TimeLine from "./TimeLine";
import { sortBy, path, isNil } from "ramda";
import { useParams } from "react-router-dom";
import Ieso from "./Ieso";

const HistoryIeso = () => {
  const [allIesos, setAllIesos] = useState();
  const [isLoading, setIsloading] = useState(false);
  let { year } = useParams();

  useEffect(() => {
    setIsloading(true);
    OpgService.getAllIesos().then(
      (response) => {
        setAllIesos(response.data);
        setIsloading(false);
      },
      (error) => {
        const allIesos =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setAllIesos(allIesos);
        setIsloading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);
  if (allIesos) {
    return (
      <div>
        {!isNil(year) && <Ieso year={year} allIesos={allIesos}></Ieso>}
        {isNil(year) &&  <DynamicContent
          title={`HISTÓRICO`}
          content={""}
          style={{ textAlign: "justify" }}
        >
          <p style={{ textAlign: "center" }}>
            <img
              alt=""
              src="//eu-central-1.linodeobjects.com/socgeol-files/production/ckeditor_assets/pictures/436/content_ieso.png"
              style={{ height: "300px", width: "300px" }}
            />
          </p>

          <p style={{ textAlign: "justify" }}>
            A&nbsp;IESO (<em>International Earth Science Olympiad</em>) &eacute;
            uma competi&ccedil;&atilde;o anual sob a &eacute;gide da IGEO (
            <em>International Geoscience Education Organisation </em>-{" "}
            <a
              href="http://www.igeoscied.org/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "0563c1", textDecoration: "underline" }}
            >
              http://www.igeoscied.org/
            </a>
            )&nbsp;que re&uacute;ne estudantes de todo o mundo, em
            representa&ccedil;&atilde;o dos respetivos pa&iacute;ses,
            selecionados, por terem sido os mais bem classificados nas
            competi&ccedil;&otilde;es nacionais, como as OPG.&nbsp; Durante mais
            de uma semana, largas dezenas de jovens (a idade limite &eacute; 18
            anos, &agrave; data de 1 de julho do ano de realiza&ccedil;&atilde;o
            da IESO) realizam testes te&oacute;ricos e pr&aacute;ticos,
            incluindo de campo, desenvolvem e apresentam projetos em equipas
            multinacionais, cumprem um interessante programa social e cultural,
            e confraternizam intensamente, tornando este evento uma
            experi&ecirc;ncia &uacute;nica nas suas vidas.
          </p>

          <p>
            A IESO tem&nbsp;como principais objetivos&nbsp;aumentar o interesse
            dos jovens pelas Ci&ecirc;ncias da Terra e sensibilizar para a sua
            import&acirc;ncia, assim como contribuir para a melhoria do seu
            ensino.
          </p>

          <p style={{ textAlign: "justify" }}>
            Os testes incidem sobre tem&aacute;ticas v&aacute;rias do
            &acirc;mbito das Ci&ecirc;ncias da Terra que ultrapassam os limites
            da Geologia, no sentido mais restrito do termo. Geof&iacute;sica,
            Meteorologia, Oceanografia, Ci&ecirc;ncias do Ambiente e,
            at&eacute;, Astronomia fornecem conte&uacute;dos para as provas (
            <a target="_blank" rel="noreferrer" href="http://www.igeoscied.org/wp-content/uploads/2022/11/IESO-official-Syllabus.pdf">
            http://www.igeoscied.org/wp-content/uploads/2022/11/IESO-official-Syllabus.pdf
            </a>
            ), obrigando os jovens elementos das v&aacute;rias equipas
            portuguesas, nas sucessivas edi&ccedil;&otilde;es,&nbsp; a um
            programa de forma&ccedil;&atilde;o complementar que os aproxime dos
            outros&nbsp;concorrentes, preparado pela CNOG e suportado pela SGP,
            com o contributo de diversas entidades, das quais se destacam, por
            aquilo que tem sido a sua a&ccedil;&atilde;o mais frequente, nesta
            mat&eacute;ria, o CCV Estremoz, a Universidade de &Eacute;vora,
            atrav&eacute;s da sua Escola de Ci&ecirc;ncias e Tecnologia, e do
            ICT &ndash; Instituto de Ci&ecirc;ncias da Terra, o Departamento de
            Geologia da Faculdade de Ci&ecirc;ncias da Universidade de Lisboa e
            o Departamento de Geoci&ecirc;ncias da Faculdade de Ci&ecirc;ncias e
            Tecnologia da Universidade Nova de Lisboa.
          </p>

          <p style={{ textAlign: "justify" }}>
            A classifica&ccedil;&atilde;o integrada dos testes te&oacute;ricos e
            pr&aacute;ticos define a classifica&ccedil;&atilde;o final
            individual que determina a atribui&ccedil;&atilde;o de medalhas.
            Estas contemplam 60% dos estudantes participantes, de acordo com a
            seguinte distribui&ccedil;&atilde;o, a partir do 1.&ordm;
            classificado: 10% - ouro, 20% - prata; 30% - bronze.
          </p>

          <p style={{ textAlign: "justify" }}>
            O programa da IESO inclui, ainda, duas provas por equipas, formadas
            por estudantes de v&aacute;rias nacionalidades, o que &eacute; uma
            marca caracter&iacute;stica deste evento, sem&nbsp;paralelo em
            iniciativas cong&eacute;neres. Uma delas, designada ITFI (
            <em>International Team Field Investigation</em>)&nbsp;envolve
            trabalho de campo (recolha de dados) e resulta numa
            apresenta&ccedil;&atilde;o em formato <em>PowerPoint</em>. A outra
            (ESP - <em>Earth System Project</em>) &eacute; um trabalho
            exclusivamente bibliogr&aacute;fico, sobre um qualquer
            fen&oacute;meno que envolva a intera&ccedil;&atilde;o entre os
            subsistemas terrestres (geosfera - atmosfera - hidrosfera -
            biosfera) e&nbsp;que d&aacute; origem a um poster, em papel. A
            avalia&ccedil;&atilde;o destes dois projetos inclui uma
            discuss&atilde;o entre os membros do j&uacute;ri, constitu&iacute;do
            por mentores dos diversos pa&iacute;ses participantes, e os
            elementos de cada equipa.
          </p>

          <p style={{ textAlign: "justify" }}>
            A influ&ecirc;ncia das Ci&ecirc;ncias do Mar e da Atmosfera no
            curr&iacute;culo da&nbsp;IESO n&atilde;o &eacute; alheia ao facto de
            esta competi&ccedil;&atilde;o ter nascido na &Aacute;sia, mais
            propriamente, em pa&iacute;ses das margens do Pac&iacute;fico, onde
            o quotidiano das popula&ccedil;&otilde;es &eacute; marcado por
            cat&aacute;strofes naturais de origem diversa, incluindo os
            fen&oacute;menos meteorol&oacute;gicos, o que se reflete nos
            programas escolares do ensino secund&aacute;rio, em pa&iacute;ses
            como, por exemplo, a Rep&uacute;blica da Coreia, onde teve lugar a
            1.&ordf; edi&ccedil;&atilde;o.
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2007</strong>&nbsp;&ndash; 1.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Rep&uacute;blica da Coreia</strong>:{" "}
            <u>Earth for Life, Universe for future Life</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2008</strong>&nbsp;&ndash; 2.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Filipinas</strong>:{" "}
            <u>Cooperation in Addressing Climate Changes</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2009</strong>&nbsp;&ndash; 3.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Taiwan</strong>: <u>Human and Environment</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2010</strong>&nbsp;&ndash; 4.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Indon&eacute;sia</strong>:{" "}
            <u>The Present is the Key to the Future</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2011</strong>&nbsp;&ndash; 5.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>It&aacute;lia</strong>:{" "}
            <u>Earth Science Renaissance: Science, Environment and Art</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2012</strong>&nbsp;&ndash; 6.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Argentina</strong>:{" "}
            <u>Energy, water and minerals for sustainable development</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2013</strong>&nbsp;&ndash; 7.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>&Iacute;ndia</strong>:{" "}
            <u>The Earth is indeed one family</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2014</strong>&nbsp;&ndash; 8.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Espanha</strong>: <u>Sea and Mountains</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2015</strong>&nbsp;&ndash; 9.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Brasil</strong>: <u>Soil</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2016</strong>&nbsp;&ndash; 10.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Jap&atilde;o</strong>:{" "}
            <u>Our Future: Earth &amp; Space</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2017</strong>&nbsp;&ndash; 11.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Fran&ccedil;a</strong>:{" "}
            <u>Standing on the Earth, gazing to the planets</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2018</strong>&nbsp;&ndash; 12.&ordf; edi&ccedil;&atilde;o
            &nbsp;&ndash; <strong>Tail&acirc;ndia</strong>:{" "}
            <u>Earth Science for All</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2019</strong>&nbsp;&ndash; 13.&ordf; edi&ccedil;&atilde;o
            &ndash; <strong>Rep&uacute;blica da Coreia</strong>:{" "}
            <u>Passion for Earth Science&hellip; </u>
            <u>Continued</u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2020</strong>&nbsp;&ndash; CANCELADA (devido ao surto
            pand&eacute;mico)
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2021</strong>&nbsp;&ndash; 14.&ordf; edi&ccedil;&atilde;o{" "}
            <strong>
              <em>Online</em>
            </strong>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2022</strong>&nbsp;&ndash; 15.&ordf; edi&ccedil;&atilde;o{" "}
            <strong>
              <em>Online </em>(It&aacute;lia)
            </strong>
            :{" "}
            <u>
              A cool inspiration for an Earth without mask, against global
              warming
            </u>
          </p>

          <p style={{ textAlign: "justify" }}>
            <strong>2023</strong>&nbsp;&ndash; 16.&ordf; edi&ccedil;&atilde;o{" "}
            <strong>
              <em>Online</em>
            </strong>
          </p>

          <p style={{ textAlign: "justify" }}>
            Portugal tem marcado presen&ccedil;a regular na&nbsp;IESO, desde a
            9.&ordf; edi&ccedil;&atilde;o, em 2015, o ano de lan&ccedil;amento
            das OPG e com resultados muito merit&oacute;rios e consistentes dos
            nossos estudantes, como se pode constatar atrav&eacute;s da
            informa&ccedil;&atilde;o reunida no texto relativo a cada
            edi&ccedil;&atilde;o.
          </p>
        </DynamicContent>}

        <div className="row">
          <div className="col-12">
            <TimeLine
              yearSchools={sortBy(path(["year", "year"]), allIesos)}
              ieso={true}
              year={parseInt(year)}
              frontPage={true}
            />
          </div>
        </div>
      </div>
    );

  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={isLoading} />
      </div>
    );
  }
};

export default HistoryIeso;
