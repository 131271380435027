import React, { useState, useEffect } from "react";
import Opg from "./Opg";
import OpgService from "../services/opgs.service";
import EventBus from "../common/EventBus";
import Loader from "./Loader";
import DynamicContent from "./DynamicContent";
import TimeLine from "./TimeLine";
import { sortBy, path, isNil } from "ramda";
import { useParams } from "react-router-dom";

const HistoryOpg = () => {
  const [allOpgs, setAllOpgs] = useState();
  let { year } = useParams();

  useEffect(() => {
    OpgService.getAllOpgs().then(
      (response) => {
        setAllOpgs(response.data);
      },
      (error) => {
        const allOpgs =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setAllOpgs(allOpgs);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  if (!!allOpgs) {
    return (
      <div>
         {!isNil(year) && <Opg year={year} allOpgs={allOpgs}></Opg>}
         {isNil(year) &&  <DynamicContent
          title={`HISTÓRICO`}
          content={""}
          style={{ textAlign: "justify" }}
        >
          <p style={{ textAlign: "justify" }}>
            Desde a sua 1.&ordf; edi&ccedil;&atilde;o, em 2015, as OPG j&aacute;
            mobilizaram 365 escolas secund&aacute;rias de todo o Pa&iacute;s.
            Apesar do contexto pand&eacute;mico, que condicionou o formato
            habitual, em 2020, 2021 e 2022, as OPG t&ecirc;m vindo a realizar-se
            todos os anos. Naquelas edi&ccedil;&otilde;es, n&atilde;o houve fase
            regional e s&oacute; na &uacute;ltima delas, a fase final regressou
            ao CCV Estremoz, para um fim-de-semana de provas (te&oacute;rica e
            pr&aacute;ticas), momentos formativos e muito conv&iacute;vio entre
            jovens estudantes vindos de todas as regi&otilde;es do Pa&iacute;s.
            Em 2020 e 2021, a fase final limitou-se, praticamente, a uma prova
            te&oacute;rica e &agrave; cerim&oacute;nia de encerramento, com
            entrega de pr&eacute;mios, no campus de Caparica da Faculdade de
            Ci&ecirc;ncias e Tecnologia&nbsp;da Universidade Nova de Lisboa,
            numa demonstra&ccedil;&atilde;o de resili&ecirc;ncia da
            organiza&ccedil;&atilde;o, das escolas, dos professores e dos
            estudantes, incentivada pela Dire&ccedil;&atilde;o-Geral da
            Educa&ccedil;&atilde;o.&nbsp;
          </p>

          <p style={{ textAlign: "justify" }}>
            Nas restantes edi&ccedil;&otilde;es, as provas da fase regional
            t&ecirc;m decorrido em escolas de acolhimento (ES Jos&eacute;
            R&eacute;gio, Vila do Conde; Col&eacute;gio Rainha Santa Isabel e ES
            Infanta D. Maria, ambas em Coimbra; ES Fernando Lopes-Gra&ccedil;a,
            Parede e ES S&atilde;o Jo&atilde;o Estoril, ambas no concelho de
            Cascais; ES Pinheiro e Rosa, Faro), distribu&iacute;das pelas quatro
            regi&otilde;es do Continente (Norte, Centro, Lisboa e Vale do Tejo,
            e Sul) e na proximidade de um Centro Ci&ecirc;ncia Viva parceiro.
          </p>

          <p style={{ textAlign: "justify" }}>
            Na Regi&atilde;o Aut&oacute;noma dos A&ccedil;ores, o Geoparque
            A&ccedil;ores tem assegurado toda a log&iacute;stica
            necess&aacute;ria &agrave; realiza&ccedil;&atilde;o da fase
            regional, em articula&ccedil;&atilde;o com as escolas das diversas
            ilhas. Na Regi&atilde;o Aut&oacute;noma da Madeira, as
            escolas,&nbsp;sob a tutela da Secretaria Regional da
            Educa&ccedil;&atilde;o, t&ecirc;m aderido, sempre
            prontamente,&nbsp;&agrave; iniciativa.
          </p>

          <p style={{ textAlign: "justify" }}>
            Para cada edi&ccedil;&atilde;o, s&atilde;o fornecidas
            informa&ccedil;&otilde;es gerais que incluem a lista de escolas
            inscritas (&quot;Lista Escolas&quot;) e a
            &quot;Classifica&ccedil;&atilde;o Final&quot;, assim como a
            divulga&ccedil;&atilde;o da iniciativa nos meios de
            comunica&ccedil;&atilde;o tradicionais e na internet.
            (&quot;AS&nbsp;OPG NA COMUNICA&Ccedil;&Atilde;O SOCIAL&quot;).
          </p>
        </DynamicContent>}


        <div className="row">
          <div className="col-12">
            <TimeLine yearSchools={sortBy(path(["year", "year"]), allOpgs)} year={parseInt(year)}/>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!allOpgs} />
      </div>
    );
  }
};

export default HistoryOpg;
