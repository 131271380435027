import Loader from "./Loader";
import IesoOpgContent from "./IesoOpgContent";

import { filter, pathEq, head } from "ramda";

const Opg = (props) => {
  const { allOpgs, year } = props;
  const findByYear = (year) => filter(pathEq(["year", "year"], parseInt(year)));
  let opg = head(findByYear(parseInt(year))(allOpgs));

  if (opg) {
    return (
      <IesoOpgContent
        year={opg.year.year}
        edition={`${opg.year.year - 1} - ${opg.year.year}`}
        title={opg.small_description}
        content={opg.description}
        other_files={opg.opg_other_files}
        final_phase_rank={
          opg.final_phase_rank_file_name ? opg.final_phase_rank : null
        }
        school_list={opg.school_list_file_name ? opg.school_list : null}
        title_s={opg.title_secundary}
        content_s={opg.description_secundary}
        opg_image={opg.image}
        allData={allOpgs}
        images={opg.opg_olimpiadas_images}
        videos={opg.opg_videos}
        mgq_linkgs={opg.mgq_link_olimpiadas}
      ></IesoOpgContent>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!year} />
      </div>
    );
  }
};

export default Opg;
