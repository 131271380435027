/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { install, gtag } from 'ga-gtag';

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";

import NavBar from "./components/NavBar";

import CarouselComponent from "./components/Carousel";
import Home from "./components/Home";
import Connection from "./components/Connection";
import WhoAreWe from "./components/WhoAreWe";
import Footer from "./components/Footer";
import DynamicPage from "./components/DynamicPage";
import ActualityDetail from "./components/ActualityDetail";
import HistoryOpg from "./components/HistoryOpg";

import Tests from "./components/Tests";
import HistoryIeso from "./components/HistoryIeso";


import { filter, propEq } from "ramda";

import DynamicPagesService from "./services/dynamic-page.service";
import ActualitiesService from "./services/actualities.service";

import { history } from "./helpers/history";

import EventBus from "./common/EventBus";

const App = (props) => {

  const hostname = window && window.location && window.location.hostname;
  
  const withRouter = (Component) => {
    const Wrapper = (props) => {
      const history = useNavigate();
      return <Component history={history} {...props} />;
    };
    return Wrapper;
  };

  // const [curUrl, setUrl] = useState("");

  install('G-9JP1QSD4FV'); 

  function _ScrollToTop(props) {
    const { pathname } = useLocation();

    if (hostname.includes("opg.socgeol.pt")) {
      gtag('event', window.location.pathname, { 'method': 'Google' });
    }

    useEffect(() => {
      // setUrl(pathname);
      window.scrollTo(0, 0);
    }, [pathname]);
    return props.children;
  }

  const [dynamicPages, setDynamicPages] = useState([]);
  const [dynamicFooter, setDynamicFooter] = useState([]);

  const [actualities, setActualities] = useState([]);
  const [linkTypes, setLinkTypes] = useState([]);

  let [topCarItems, setTopCarItems] = useState([]);
  let [lowerCarItems, setLowerCarItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    ActualitiesService.getAllActualities().then(
      (response) => {
        setActualities(response.data.news);
        setLinkTypes(response.data.links);
        setTopCarItems(
          response.data.news_c
            .concat(response.data.dp_c)
            .concat(response.data.photos)
            .concat(response.data.olimpiadas_c)
            .concat(response.data.ieso_c)
        );
        setLowerCarItems(
          response.data.news_h
            .concat(response.data.dp_h)
            .concat(response.data.olimpiadas_h)
            .concat(response.data.ieso_h)
        );
        setIsLoading(false);
      },
      (error) => {
        setActualities([]);
        setIsLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

    DynamicPagesService.getDynamicPages().then(
      (response) => {
        setDynamicPages(response.data);
        setDynamicFooter(filter(propEq("is_footer", true), response.data));
      },
      (error) => {
        setDynamicPages(undefined);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  function HeaderView() {
    const showCar = ["/", "who_are_we", "/history", "/tests", "/ieso"];
    let location = useLocation();
    return (
      showCar.includes(location.pathname) && (
        <CarouselComponent topCarItems={topCarItems} />
      )
    );
  }

  const ScrollToTop = withRouter(_ScrollToTop);

  return (
    <BrowserRouter history={history}>
      <div>
        <div className="container mt-5 pt-10">
          <ScrollToTop>
            <NavBar dynamicPages={dynamicPages} />

            <main className="mt-5">
              <div className="container bg-light pt-3">
                <HeaderView />
                <div className="mt-3">
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={
                        <Home
                          actualities={actualities}
                          dynamicPages={dynamicPages}
                          lowerCarItems={lowerCarItems}
                          topCarItems={topCarItems}
                          isLoading={isLoading}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/pages/:pageId"
                      element={<DynamicPage />}
                    />

                    <Route
                      exact
                      path="/actualities/:actualityId"
                      element={<ActualityDetail />}
                    />

                    <Route
                      exact
                      path="/links_pt"
                      element={
                        <Connection national={true} linkTypes={linkTypes} />
                      }
                    />
                    <Route
                      exact
                      path="/links_int"
                      element={
                        <Connection national={false} linkTypes={linkTypes} />
                      }
                    />
                    <Route exact path="/who_are_we" element={<WhoAreWe />} />
                    <Route exact path="/history" element={<HistoryOpg />} />
                    <Route exact path="/opg/:year" element={<HistoryOpg />} />
                    <Route exact path="/tests" element={<Tests />} />
                    <Route exact path="/ieso" element={<HistoryIeso />} />
                    <Route exact path="/ieso/:year" element={<HistoryIeso />} />
                    <Route
                      exact
                      path="/actuality_opg/:actualityId"
                      element={<ActualityDetail />}
                    />
                  </Routes>
                  <div id="scroller"></div>
                </div>
                <Footer dynamicFooter={dynamicFooter} />
              </div>
            </main>
          </ScrollToTop>
        </div>
        {/* <AuthVerify logOut={logOut}/> */}
      </div>
    </BrowserRouter>
  );
};

export default App;
