/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";

function getAllOpgs() {
  return axios.get(`${API_PATH}v1/olimpiadas`);
}

function getAllIesos() {
  return axios.get(`${API_PATH}v1/ieso`);
}

function getOpg(id) {
  return axios.get(`${API_PATH}v1/olimpiadas/${id}`);
}

function getLastOpg() {
  return axios.get(`${API_PATH}v1/olimpiadas?last=true`);
}

function getDocumentsOpgTypes() {
  return axios.get(`${API_PATH}v1/opg_documents_types`);
}

export default {
  getAllOpgs,
  getOpg,
  getLastOpg,
  getAllIesos,
  getDocumentsOpgTypes
};
