/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ActualityBox from "./ActualityBox";
import CardGroup from "react-bootstrap/CardGroup";
import { compose, not, propEq, filter } from "ramda";

const ActualityList = (props) => {
  let { actualities } = props;
  const { national = true } = props;

  actualities = filter(
    compose(not, propEq("national", !national)),
    actualities
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <h2 className="txt-brown text-uppercase mt-3 mb-3">
            Notícias
            {/* Notícias {national ? "nacionais" : "internacionais"}{" "} */}
          </h2>
        </div>
      </div>
      <div className="row">
        {actualities.length === 0 && (
          <span> Não existe Informação para apresentar...</span>
        )}
        {actualities !== undefined &&
          actualities.map((actuality) => {
            return (
              <CardGroup
                key={`m` + actuality.id}
                className="col-xs-12 col-sm-6 col-xl-3 p-0"
              >
                <ActualityBox key={actuality.id} actuality={actuality} />{" "}
              </CardGroup>
            );
          })}
      </div>
    </>
  );
};

export default ActualityList;
