import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader = (props) => {
  const { isLoading } = props;

  if (isLoading) {
    return <Spinner animation="border" className="m-2"/>;
  } else {
    return "";
  }
};

export default Loader;
