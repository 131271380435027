import React from "react";
import { Row, Col } from "react-bootstrap";

const WhoAreWe = () => {
  return (
    <Row>
      <Col md={12}>
        <h2 className="txt-darkorange bold mb-1">Quem somos</h2>{" "}
      </Col>
      <Col md={9} xs={12} style={{ textAlign: "justify" }}>
        As OPG são concursos anuais que visam a resolução de questões teóricas e
        problemas práticos de Geologia, dirigidos aos estudantes do ensino
        secundário português (11.º ano de escolaridade) e são uma iniciativa da
        Sociedade Geológica de Portugal, que conta com o apoio do Ministério de
        Educação e Ciência, da International Geoscience Education Organisation
        (IGEO), da ANCCT - Ciência Viva e dos CCV do Lousal e de Estremoz. Os
        principais objetivos das OPG são a dinamização do estudo e ensino da
        Geologia no ensino secundário; a aproximação entre as escolas
        secundárias e as universidades; a divulgação da Geologia como ciência e
        a captação de vocações para prosseguimento de estudos a nível superior.
      </Col>
      <Col md={3} xs={12} className="text-center">
        <img
          src="/images/logo_olimpiadas_portuguesas.png"
          alt="Olimpiadas"
          className="img-fluid responsive"
        />
        <h3 className="txt-green mb-3 mt-3">REGULAMENTOS</h3>
        <a
          href="/regulamento_opg-alteracao2019.pdf"
          target="_blank"
          className="txt-darkgray mt-2"
        >
          Regulamento
        </a>{" "}
      </Col>
    </Row>
  );
};

export default WhoAreWe;
