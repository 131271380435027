import React from "react";

import ActualityList from "./ActualityList";
import Highlights from "./Highlights";
import Loader from "./Loader";

const Home = (props) => {
  const { actualities, dynamicPages, lowerCarItems, topCarItems, isLoading } =
    props;

  return (
    <>
      {!isLoading && (
        <Highlights
          dynamicPages={dynamicPages}
          lowerCarItems={lowerCarItems}
          topCarItems={topCarItems}
        />
      )}

      {!isLoading && actualities !== "" && (
        <ActualityList actualities={actualities} national={true} />
      )}
      <div className="d-flex justify-content-center">
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
};

export default Home;
