import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import PagesService from "../services/dynamic-page.service";
import EventBus from "../common/EventBus";
import DynamicContent from "./DynamicContent";

const Page = (props) => {
  const [page, setPage] = useState();
  let { pageId } = useParams();

  useEffect(() => {
    PagesService.getDynamicPage(pageId).then(
      (response) => {
        setPage(response.data);
      },
      (error) => {
        const _page =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setPage(_page);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [pageId]);

  return (
    !!page && <DynamicContent title={page.description} content={page.content} />
  );
};

export default Page;