let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname.includes("opg.qa.socgeol.pt")) {
  backendHost = "https://api.qa.socgeol.pt/api/";
} else if (hostname.includes("opg.socgeol.pt")) {
  backendHost = "https://api.socgeol.pt/api/";
} else {
  backendHost = "http://localhost:3000/api/";
}

export const RECAPTCHA_SITE = "6LeTx_0dAAAAAAexKXldP5ZG1tcTYpjzghDpGWLS";

export const API_PATH = `${backendHost}`;

export const COLORS = [
  { color: "#ffffff", backgroundColor: "#c67200" },
  { color: "#ffffff", backgroundColor: "#eedb74" },
  { color: "#ffffff", backgroundColor: "#998f76" },
  { color: "#ffffff", backgroundColor: "#67893f" },
  { color: "#ffffff", backgroundColor: "#45515d" },
  { color: "#ffffff", backgroundColor: "#baad76" },
  { color: "#ffffff", backgroundColor: "#498a77" },
  { color: "#ffffff", backgroundColor: "#61295f" },
  { color: "#ffffff", backgroundColor: "#61295f" },
  { color: "#ffffff", backgroundColor: "#ee9e74" },
  { color: "#ffffff", backgroundColor: "#eedb74" },
  { color: "#ffffff", backgroundColor: "#7487ee" },
  { color: "#ffffff", backgroundColor: "#ee9e74" },
  { color: "#ffffff", backgroundColor: "#74c4ee" },
  { color: "#ffffff", backgroundColor: "#db74ee" },
  { color: "#ffffff", backgroundColor: "#74eedb" },
  { color: "#ffffff", backgroundColor: "#ee7487" },
];
