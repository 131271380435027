import React, { useState, useEffect } from "react";
import OpgService from "../services/opgs.service";
import EventBus from "../common/EventBus";
import Loader from "./Loader";
import Table from "react-bootstrap/Table";
import {
  isEmpty,
  last,
  head,
  path,
  isNil,
  sortBy,
  reject,
  equals,
  filter,
  pathEq,
  propEq,
  prop,
} from "ramda";
import TimeLineTests from "./TimeLineTests";
import { COLORS } from "../config";

const Tests = () => {
  const [allOpgs, setAllOpgs] = useState([]);
  const [documentTypes, setdocumentTypes] = useState([]);
  // const [filteredOpgs, setFilteredOpgs] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [colorList, setColorList] = useState([]);

  const [yearSchoolFaseD, setYearSchoolFase] = useState([]);
  const [yearRegionalFaseD, setYearRegionalFase] = useState([]);
  const [yearFinalPhaseD, setYearFinalPhase] = useState([]);

  let yearSchoolFase = [];
  let yearRegionalFase = [];
  let yearFinalPhase = [];

  const resetData = () => {
    setYearSchoolFase([]);
    setYearRegionalFase([]);
    setYearFinalPhase([]);
  };

  const updateSf = (year) => {
    let values = [];
    if (yearSchoolFase.includes(year)) {
      values = reject(equals(year), yearSchoolFase);
      yearSchoolFase = values;
    } else {
      yearSchoolFase.push(year);
    }
    setYearSchoolFase(yearSchoolFase);
  };

  const updateRf = (year) => {
    let values = [];
    if (yearRegionalFase.includes(year)) {
      values = reject(equals(year), yearRegionalFase);
      yearRegionalFase = values;
    } else {
      yearRegionalFase.push(year);
    }
    setYearRegionalFase(yearRegionalFase);
  };

  const updateFp = (year) => {
    let values = [];
    if (yearFinalPhase.includes(year)) {
      values = reject(equals(year), yearFinalPhase);
      yearFinalPhase = values;
    } else {
      yearFinalPhase.push(year);
    }
    setYearFinalPhase(yearFinalPhase);
  };

  let colorsUsed = [];

  function shuffler(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  const changeColor = () => {
    let choosenColor = {};

    const shuffled = shuffler(COLORS);

    for (let index = 0; index < shuffled.length; index++) {
      const item = shuffled[index];

      if (!colorsUsed.includes(item.backgroundColor)) {
        colorsUsed.push(item.backgroundColor);
        choosenColor = item;
        break;
      }
    }
    return choosenColor;
  };

  useEffect(() => {
    setIsloading(true);
    OpgService.getDocumentsOpgTypes().then(
      (response) => {
        setdocumentTypes(
          filter(propEq("opg_documents_types_type_id", 1), response.data)
        );
        setIsloading(false);
      },
      (error) => {
        const _documentTypes =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setdocumentTypes(_documentTypes);
        setIsloading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

    OpgService.getAllOpgs().then(
      (response) => {
        setAllOpgs(response.data);
        setIsloading(false);
      },
      (error) => {
        const _AllOpgs =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setAllOpgs(_AllOpgs);
        setIsloading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  let withFilters = false;

  if (!isEmpty(allOpgs)) {
    const sortedOpgs = sortBy(path(["year", "year"]), allOpgs);
    const yearEnd = path(["year", "year"], last(sortedOpgs));
    const yearBegin = path(["year", "year"], head(sortedOpgs));
    const colors = [];

    //martelado mas funciona!!!
    const presentYers = sortedOpgs.map((item) => item.year.year);

    for (let index = yearBegin; index <= yearEnd; index++) {
      colors.push(changeColor());
    }
    if (isEmpty(colorList)) {
      setColorList(colors);
    }

    const findByYear = (year) =>
      filter(pathEq(["year", "year"], parseInt(year)));

    let filteredDocuments = [];

    if (!isEmpty(yearSchoolFaseD)) {
      withFilters = true;
      let filtered = prop(
        "opg_documents_olimpiadas",
        last(findByYear(parseInt(yearSchoolFaseD[0]))(allOpgs))
      );

      let filteredAnswers = prop(
        "tests_answers",
        last(findByYear(parseInt(yearSchoolFaseD[0]))(allOpgs))
      );

      const tmp_filtered = filter(propEq("opg_documents_type_id", 1), filtered);

      const tmp_filtered_answers = filter(
        propEq("tests_answers_type_id", 1),
        filteredAnswers
      );

      if (!isEmpty(tmp_filtered) || !isEmpty(tmp_filtered_answers)) {
        filteredDocuments = [
          ...filteredDocuments,
          {
            year: parseInt(yearSchoolFaseD[0]),
            docs: tmp_filtered_answers,
          },
        ];

        filteredDocuments = [
          ...filteredDocuments,
          {
            year: parseInt(yearSchoolFaseD[0]),
            docs: tmp_filtered,
          },
        ];
      }
    }

    if (!isEmpty(yearRegionalFaseD)) {
      withFilters = true;
      let filtered = prop(
        "opg_documents_olimpiadas",
        last(findByYear(parseInt(yearRegionalFaseD[0]))(allOpgs))
      );

      let filteredAnswers = prop(
        "tests_answers",
        last(findByYear(parseInt(yearRegionalFaseD[0]))(allOpgs))
      );

      const tmp_filtered = filter(propEq("opg_documents_type_id", 2), filtered);

      const tmp_filtered_answers = filter(
        propEq("tests_answers_type_id", 2),
        filteredAnswers
      );

      if (!isEmpty(tmp_filtered) || !isEmpty(tmp_filtered_answers)) {
        filteredDocuments = [
          ...filteredDocuments,
          {
            year: parseInt(yearRegionalFaseD[0]),
            docs: tmp_filtered_answers,
          },
        ];

        filteredDocuments = [
          ...filteredDocuments,
          {
            year: parseInt(yearRegionalFaseD[0]),
            docs: tmp_filtered,
          },
        ];
      }
    }

    if (!isEmpty(yearFinalPhaseD)) {
      withFilters = true;
      let filtered = prop(
        "opg_documents_olimpiadas",
        last(findByYear(parseInt(yearFinalPhaseD[0]))(allOpgs))
      );

      let filteredAnswers = prop(
        "tests_answers",
        last(findByYear(parseInt(yearFinalPhaseD[0]))(allOpgs))
      );

      const tmp_filtered = filter(propEq("opg_documents_type_id", 3), filtered);

      const tmp_filtered_answers = filter(
        propEq("tests_answers_type_id", 3),
        filteredAnswers
      );

      if (!isEmpty(tmp_filtered) || !isEmpty(tmp_filtered_answers)) {
        filteredDocuments = [
          ...filteredDocuments,
          {
            year: parseInt(yearFinalPhaseD[0]),
            docs: tmp_filtered_answers,
          },
        ];

        filteredDocuments = [
          ...filteredDocuments,
          {
            year: parseInt(yearFinalPhaseD[0]),
            docs: tmp_filtered,
          },
        ];
      }
    }
    const sortedData = sortBy(path(["year"]), filteredDocuments) || [];

    return (
      <>
        <div className="row">
          <div className="col-12 d-flex  flex-column justify-content-center">
            {documentTypes.map((item, index) => (
              <div className="text-center p-3" key={`${item.id}_${index}`}>
                <h2 className="txt-lightgray m-x-2">{item.description}</h2>
                <TimeLineTests
                  data={
                    index === 0
                      ? yearSchoolFaseD
                      : index === 1
                      ? yearRegionalFaseD
                      : yearFinalPhaseD
                  }
                  type={index === 0 ? "sf" : index === 1 ? "rp" : "fp"}
                  updateFunction={
                    index === 0 ? updateSf : index === 1 ? updateRf : updateFp
                  }
                  presentYers={presentYers}
                  yearEnd={yearEnd}
                  yearBegin={yearBegin}
                  colors={colorList}
                />
              </div>
            ))}
            <div className="text-center ">
              <span
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-success btn-fixed-width shadow"
                onClick={() => resetData()}
              >
                Limpar
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-5">
            {!isEmpty(allOpgs) && (
              <Table striped bordered hover size="sm" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Ano</th>
                    <th className="text-center">Tipo Documento</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(sortedData) &&
                    sortedData.map((item) =>
                      item.docs.map((doc) => {
                        return (
                          <tr key={item.year + "-" + doc.id}>
                            <td className="text-center align-middle">
                              <b>{item.year}</b>
                            </td>
                            <td className="text-center align-middle">
                              <b>
                                <u>
                                  {!isNil(doc.opg_documents_type)
                                    ? path(
                                        ["opg_documents_type", "description"],
                                        doc
                                      )
                                    : path(
                                        ["tests_answers_type", "description"],
                                        doc
                                      )}{" "}
                                </u>
                              </b>
                            </td>
                            <td className="align-left">
                              {!isNil(doc.mgq_link) && (
                                <a
                                  href={doc.mgq_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-secondary  shadow btn-fixed-width m-1"
                                >
                                  Fazer teste
                                </a>
                              )}
                              <a
                                href={doc.document}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="btn btn-secondary  shadow btn-fixed-width m-1"
                              >
                                Transferir
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  {isEmpty(sortedData) &&
                    !withFilters &&
                    allOpgs.map((opgItem) => {
                      if (
                        !isNil(opgItem.opg_documents_olimpiadas) &&
                        !isEmpty(opgItem.opg_documents_olimpiadas)
                      ) {
                        let data = opgItem.opg_documents_olimpiadas.concat(
                          opgItem.tests_answers
                        );

                        if (!isEmpty(data)) {
                          return data.map((opgDoc) => {
                            return (
                              <tr
                                key={
                                  opgItem.year.year +
                                  "-" +
                                  opgItem.id +
                                  "-" +
                                  opgDoc.id
                                }
                              >
                                <td className="text-center align-middle">
                                  <b>{opgItem.year.year}</b>
                                </td>
                                <td className="text-center align-middle bold">
                                  <b>
                                    <u>
                                      {!isNil(opgDoc.opg_documents_type)
                                        ? path(
                                            [
                                              "opg_documents_type",
                                              "description",
                                            ],
                                            opgDoc
                                          )
                                        : path(
                                            [
                                              "tests_answers_type",
                                              "description",
                                            ],
                                            opgDoc
                                          )}
                                    </u>
                                  </b>
                                </td>
                                <td className="align-left">
                                  <a
                                    href={opgDoc.document}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-secondary  shadow btn-fixed-width m-1"
                                  >
                                    Transferir
                                  </a>
                                  {!isNil(opgDoc.mgq_link) && (
                                    <a
                                      href={opgDoc.mgq_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="btn btn-secondary  shadow btn-fixed-width m-1"
                                    >
                                      Fazer teste
                                    </a>
                                  )}
                                </td>
                              </tr>
                            );
                          });
                        } else {
                          return <tr key={Math.random()}></tr>;
                        }
                      } else {
                        return "";
                      }
                    })}
                  {((isEmpty(sortedData) && withFilters) ||
                    (isEmpty(allOpgs) && !withFilters)) && (
                    <tr>
                      <td colSpan={3} className="text-center p-2">
                        Não foram encontrados testes.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </>
    );
  } else {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center">
          <Loader isLoading={isLoading} />
        </div>
      );
    } else {
      return <p>Sem dados para apresentar.</p>;
    }
  }
};

export default Tests;
