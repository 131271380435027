/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";

function getAllActualities() {
  return axios.get(`${API_PATH}v1/get_structured_data_opg`);
};

function getActuality(id) {
  return axios.get(`${API_PATH}v1/actuality_opg/${id}`);
}

export default {
  getAllActualities,
  getActuality,
};
