import Loader from "./Loader";
import IesoOpgContent from "./IesoOpgContent";

import { filter, pathEq, head } from "ramda";

const Ieso = (props) => {
  const { allIesos, year } = props;
  const findByYear = (year) => filter(pathEq(["year", "year"], parseInt(year)));
  let ieso = head(findByYear(year)(allIesos));

  if (ieso) {
    return (
      <IesoOpgContent
        year={ieso.year.year}
        edition={`${ieso.year.year}`}
        title={ieso.small_description}
        content={ieso.description}
        title_s={ieso.title_secundary}
        content_s={ieso.description_secundary}
        other_files={ieso.ieso_other_files}
        test_files={ieso.opg_documents_iesos}
        tests_answers={ieso.ieso_tests_answers}
        opg_image={ieso.image}
        allData={allIesos}
        videos={ieso.ieso_videos}
        images={ieso.opg_ieso_images}
        ieso={true}
        p_list={ieso.p_list_file_name ? ieso.p_list : null}
        pt_team={ieso.pt_team_file_name ? ieso.pt_team : null}
        frontPage={false}
        mgq_linkgs={ieso.mgq_link_iesos}
      ></IesoOpgContent>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!year} />
      </div>
    );
  }
};

export default Ieso;
